import React from 'react';
import { Element } from '~/components/Element';
import { extendClassList } from '~/utilities/css';
import styles from './styles';
import Icon from '../Icon';

type Props<T extends React.ElementType = 'div' | 'p' | 'h1' | 'h2' | 'h3'> = {
	as?: T,
	level?: 'danger' | 'warning' | 'info' | 'success',
	children: React.ReactNode,
	className?: null | string | ((values?: string) => string),
} & Omit<React.ComponentPropsWithoutRef<T>, 'className'>;

function Alert({
	as = 'div',
	level = 'info',
	className = null,
	children,
	...delegatedProps
}: Props) {
	const defaultStyles = `${styles.container} ${getAlertTheme(level).styles}`;

	return (
		<Element
			as={as}
			className={extendClassList(className ?? defaultStyles, defaultStyles)}
			role="alert"
			aria-live="assertive"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...delegatedProps}
		>
			<Icon className="uic-w-15 uic-h-15 uic-flex-shrink-0" name={getAlertTheme(level).icon} />
			{children}
		</Element>
	);
}

function getAlertTheme(name: 'danger' | 'warning' | 'info' | 'success') {
	const icons = {
		danger: 'block',
		warning: 'warning',
		info: 'info-with-circle',
		success: 'check',
	} as const;

	return { styles: styles[name], icon: icons[name] };
}

export { Alert as default, Alert };
