const styles = {
	container: 'uic-flex uic-items-center uic-gap-10 uic-p-20 uic-rounded-md uic-text-xs uic-font-medium uic-shadow-sm',

	// NOTE: colors are not currently a part of our theme but are a part of the spec
	// TODO: maybe break these up a bit better by doing something like `background` and `foreground`
	danger: 'uic-bg-[#fec7cc] uic-text-[#910210] uic-fill-[#910210]',
	warning: 'uic-bg-[#fff2a3] uic-text-[#706000] uic-fill-[#706000]',
	info: 'uic-bg-[#afd2fc] uic-text-[#043775] uic-fill-[#043775]',
	success: 'uic-bg-[#abefd7] uic-text-[#11573e] uic-fill-[#11573e]',
};

export { styles as default, styles as alertStyles };
